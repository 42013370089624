// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Parallax, Background } from "react-parallax";
import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import TLDR from "./components/TLDR";
import Footer from "./components/Footer";
import WhyHireMeCLI from "./components/WhyHireMeCLI";

import backgroundImage from "./assets/img/background.png";

import "./App.css";

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Parallax strength={500}>
                  <Background className="custom-bg">
                    <img
                      src={backgroundImage}
                      alt="background"
                      style={{
                        width: "100%",
                        height: "100%",
                        opacity: 0.5,
                      }}
                    />
                  </Background>
                  <div>
                    <main id="main" className="site-main">
                      <TLDR />
                      <About />
                      <Skills />
                      <WhyHireMeCLI />
                      <Projects />
                      <Contact />
                    </main>
                  </div>
                </Parallax>
              </>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
