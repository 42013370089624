import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SkillsRadarChart from "./SkillsRadarChart";
import SkillsScrollWindow from "./SkillsScrollWindow";
import "../styles/Skills.css";
const Skills = () => {
  const [activeSkill, setActiveSkill] = useState(null);

  const skills = [
    {
      name: "LLM & Prompt Engineering",
      level: 9,
      details:
        "Expert in engineering and refining prompts for state-of-the-art LLMs (GPT series, Llama, Claude). Developed testing frameworks to evaluate and maximize AI model capabilities.",
      examples:
        "Created a dynamic prompt generation system for personalized content creation, Implemented a multi-agent conversation system using LLMs",
      projects: [
        { name: "AI Chatbot Framework", link: "/projects/ai-chatbot" },
        {
          name: "LLM-powered Content Generator",
          link: "/projects/content-generator",
        },
      ],
    },
    {
      name: "Neural Networks",
      level: 8,
      details:
        "Proficient in architecting and implementing various neural network types, including graph neural networks, for complex data analysis tasks.",
      examples:
        "Developed a graph neural network for molecular property prediction, Implemented a convolutional neural network for image classification",
      projects: [
        {
          name: "Molecular Property Predictor",
          link: "/projects/mol-predictor",
        },
        {
          name: "Image Classification System",
          link: "/projects/image-classifier",
        },
      ],
    },
    {
      name: "Machine Learning",
      level: 9,
      details:
        "Extensive experience in developing ML pipelines, optimizing models, and applying ML to diverse domains such as NLP, computer vision, and molecular biology.",
      examples:
        "Built an end-to-end ML pipeline for predictive maintenance, Optimized a recommendation system using ensemble methods",
      projects: [
        {
          name: "Predictive Maintenance System",
          link: "/projects/predictive-maintenance",
        },
        {
          name: "Advanced Recommendation Engine",
          link: "/projects/recommendation-engine",
        },
      ],
    },
    {
      name: "NLP",
      level: 8,
      details:
        "Strong expertise in natural language processing, including sentiment analysis, text classification, and leveraging transformers for advanced NLP solutions.",
      examples:
        "Developed a sentiment analysis tool for social media monitoring, Created a text classification system for customer support ticket routing",
      projects: [
        {
          name: "Social Media Sentiment Analyzer",
          link: "/projects/sentiment-analyzer",
        },
        {
          name: "Ticket Classification System",
          link: "/projects/ticket-classifier",
        },
      ],
    },
    {
      name: "Data Science",
      level: 7,
      details:
        "Skilled in data analysis, predictive modeling, and utilizing tools like Pandas, NumPy, and Matplotlib for insightful data visualizations.",
      examples:
        "Conducted exploratory data analysis on large-scale customer datasets, Created interactive dashboards for business intelligence",
      projects: [
        {
          name: "Customer Behavior Analysis",
          link: "/projects/customer-analysis",
        },
        { name: "Interactive BI Dashboard", link: "/projects/bi-dashboard" },
      ],
    },
    {
      name: "AI Business Solutions",
      level: 8,
      details:
        "Developed AI-driven business solutions, including an Emotional Intelligence API and AI-powered molecular biology frameworks.",
      examples:
        "Built an Emotional Intelligence API for sentiment analysis in customer interactions, Developed an AI-powered drug discovery platform",
      projects: [
        { name: "Emotional Intelligence API", link: "/projects/ei-api" },
        {
          name: "AI-Powered Drug Discovery Platform",
          link: "/projects/drug-discovery",
        },
      ],
    },
    {
      name: "Python",
      level: 9,
      details:
        "Advanced proficiency in Python programming, with extensive experience in AI/ML libraries and frameworks such as TensorFlow, PyTorch, and Hugging Face.",
      examples:
        "Developed a custom deep learning framework in Python, Created efficient data processing pipelines using Python's asyncio",
      projects: [
        {
          name: "Custom Deep Learning Framework",
          link: "/projects/dl-framework",
        },
        {
          name: "Async Data Processing Pipeline",
          link: "/projects/async-pipeline",
        },
      ],
    },
    {
      name: "AI-Driven Business Solutions",
      level: 9,
      details:
        "Combines technical AI expertise with business acumen, leveraging insights from sales and consumer behavior to create impactful AI applications.",
      examples:
        "Developed an AI-driven sales forecasting tool, Created a customer churn prediction model for a SaaS company",
      projects: [
        { name: "AI Sales Forecasting Tool", link: "/projects/sales-forecast" },
        { name: "Customer Churn Predictor", link: "/projects/churn-predictor" },
      ],
    },
  ];

  const handleSkillClick = (skillName) => {
    setActiveSkill(skillName);
  };

  // Optional: Reset activeSkill after scrolling to allow repetitive clicks
  useEffect(() => {
    if (activeSkill) {
      const timer = setTimeout(() => {
        setActiveSkill(null);
      }, 1000); // Adjust time as needed
      return () => clearTimeout(timer);
    }
  }, [activeSkill]);

  return (
    <div
      className="skills-container p-4"
      style={{
        backgroundColor: "#1e1e1e",
        borderTop: "1px solid white",
        borderBottom: "1px solid white",
        minHeight: "600px",
      }}
    >
      <section id="skills" className="site-section section-skills">
        <Container>
          <Row className="mb-4">
            <Col>
              <h3 className="section-header">Skills</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="mb-4 mb-md-0">
              <h5 style={{ color: "#ffffff", marginBottom: "10px" }}>
                Click on the points to learn more:
              </h5>
              <div style={{ height: "400px", maxWidth: "100%" }}>
                <SkillsRadarChart
                  skills={skills}
                  onSkillClick={handleSkillClick}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <SkillsScrollWindow skills={skills} activeSkill={activeSkill} />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Skills;
