// src/components/Footer/Footer.js

import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import { FaLinkedin, FaGithub, FaEnvelope, FaArrowUp } from "react-icons/fa";

import "../styles/Footer.css"; // Import the CSS file

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,

      behavior: "smooth",
    });
  };

  return (
    <footer id="colophon" className="site-footer">
      <Container>
        <Row>
          <Col sm={4} xs={12} className="mb-3">
            <a
              className="icon-link linkedin-bg"
              href="https://linkedin.com/in/weslagarde-43ab38143"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </a>

            <a
              className="icon-link github-bg"
              href="https://github.com/wesley7137"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="GitHub"
            >
              <FaGithub />
            </a>

            <a
              className="icon-link email-bg"
              href="mailto:weslagarde@gmail.com"
              aria-label="Email"
            >
              <FaEnvelope />
            </a>
          </Col>

          <Col sm={4} className="text-center mb-3">
            <p className="copyright">© 2024 Wes Lagarde. All Rights Reserved</p>
          </Col>

          <Col sm={4} className="text-right mb-3">
            <button
              className="icon-link icon-up-bg"
              onClick={scrollToTop}
              aria-label="Scroll to top"
            >
              <FaArrowUp />
            </button>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
