// Projects.js

import React, { useState } from "react";

import { Container, Row, Col, Button } from "react-bootstrap";

import PortfolioModal from "./PortfolioModal";

import rlAgentImage from "../assets/img/rl-agent.png";

import chatalyticsImage from "../assets/img/chatalytics.png";

import wristCoderImage from "../assets/img/wrist_coder.png";

import researchAssistImage from "../assets/img/research-assist.png";

import emotionAIImage from "../assets/img/emotion-ai.png";

import intelliRouteImage from "../assets/img/intelliroute.png";

import autoBloggerImage from "../assets/img/autoblogger.png";

import knowledgeGraphImage from "../assets/img/knowledge-graph.png";

import babyFAppImage from "../assets/img/babf.png";

import "../styles/Projects.css";

import { motion } from "framer-motion";

import { styled } from "@mui/system";

import { Typography, Box } from "@mui/material";

// Styled components

const StyledCard = styled(motion.div)(({ theme }) => ({
  height: "300px",

  backgroundSize: "cover",

  backgroundPosition: "center",

  display: "flex",

  flexDirection: "column",

  justifyContent: "space-between",

  position: "relative",

  borderRadius: "15px",

  boxShadow: "0 10px 20px rgba(0,0,0,0.5), 0 6px 6px rgba(0,0,0,0.23)",

  transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",

  "&:hover": {
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",

    transform: "translateY(-10px)",
  },

  [theme.breakpoints.down("sm")]: {
    height: "250px",
  },
}));

const StyledCardContent = styled(Box)(({ theme }) => ({
  height: "100%",

  display: "flex",

  flexDirection: "column",

  justifyContent: "space-between",

  padding: 0,
}));

const CardBackdrop = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.6)",

  background:
    "linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.8) 100%)",

  backdropFilter: "blur(12px)",

  WebkitBackdropFilter: "blur(5px)",

  padding: theme.spacing(2),

  height: "35%",

  width: "100%",
}));

const Projects = () => {
  const [modalShow, setModalShow] = useState(false);

  const [selectedProject, setSelectedProject] = useState(null);

  const portfolioData = [
    {
      title: "RL Biomolecular Agent",

      description:
        "Novel RL-driven multi-agent framework for biomolecular design.",

      image: rlAgentImage,

      content:
        "Developed a novel Reinforcement Learning-driven multi-agent framework for biomolecular design targeting hallmarks of aging. Integrated advanced cheminformatics tools and multi-objective optimization to discover potential therapeutics.",
    },

    {
      title: "Chatalytics AI",

      description:
        "API service merging chatbot interactions with advanced analytics.",

      image: chatalyticsImage,

      content:
        "ChatAlytics.ai is an innovative API service that merges the power of chatbot interactions with advanced analytics, enabling businesses and developers to extract valuable insights from conversational data across various domains. By leveraging NLP and ML techniques, it offers tools to analyze, understand, and enhance user engagement through intelligent conversation analysis.",
    },

    {
      title: "Wrist Coder",

      description:
        "Coding assistant for mobile devices and smart watches with a language interface and automous coding abilities hosted on your home computer or cloud server.",

      image: wristCoderImage,

      content:
        "Wrist Coder is a voice assistant application that integrates speech-to-text (STT), text-to-speech (TTS), and WebSocket communication to interact with a large language model (LLM) for real-time query processing. Designed for mobile devices and smartwatches, it provides a seamless user interface for continuous interaction and response.",
    },

    {
      title: "Knowledge Graph",

      description:
        "Custom designed Virtual Immersive Knowledge Graph Visualization Tool.",

      image: knowledgeGraphImage,

      content:
        "This is a custom designed Virtual Immersive Knowledge Graph Visualization Tool. It was designed to help users visualize and interact with complex knowledge graphs in a more intuitive way. It was built using Python, NetworkX, PyTorch Geometric, Three.js, and React.",

      libraries: ["Python", "NetworkX", "PyTorch Geometric", "Docker", "AWS"],

      skills: [
        "AI",

        "ML",

        "NLP",

        "Graph Neural Networks",

        "Python",

        "NetworkX",

        "PyTorch Geometric",

        "HTML",

        "CSS",

        "AWS",
      ],
    },

    {
      title: "Research Assist AI",

      description: "AI-powered tool to streamline the research process.",

      image: researchAssistImage,

      content:
        "Research Assist AI is designed to streamline the research process by providing intelligent assistance in literature review, data analysis, and hypothesis generation. It leverages advanced NLP techniques to summarize academic papers, extract key insights, and suggest relevant research directions.",
    },

    {
      title: "Emotion AI",

      description: "Emotional Intelligence API for AI applications.",

      image: emotionAIImage,

      content:
        "Emotion AI is an advanced sentiment analysis tool that interprets and responds to human emotions through natural language processing and facial recognition technologies. It is designed to enhance user interactions in applications such as customer service, mental health, and interactive entertainment.",
    },

    {
      title: "IntelliRoute AI",

      description:
        "Smart LLM routing API for optimizing large language model usage for higher accuracy and cost-effectiveness.",

      image: intelliRouteImage,

      content:
        "IntelliRoute AI is cost-effective, hosted API service that helps businesses optimize their usage of large language models (LLMs) by intelligently routing requests to appropriate models based on the required complexity. Implements a custom trained classification neural network model to identify when to utilize simple or complex models based on the complexity of the query.",
    },

    {
      title: "AutoBlogger",

      description: "AI-powered automated blogging platform.",

      image: autoBloggerImage,

      content:
        "AutoBlogger is an automated blogging platform that leverages AI to generate, curate, and publish high-quality blog content. It integrates natural language generation models to create engaging articles tailored to specific niches and audiences.",
    },

    {
      title: "Build-a-BF App",

      description: "AI Companion Application for Building a virtual BF.",

      image: babyFAppImage,

      content:
        "The Build-a-BF App is an AI companion application designed to assist users in building a virtual BF  companion. It leverages advanced AI models to generate personalized responses, provide emotional support, and engage in interactive conversations.",

      libraries: [
        "React-Native",

        "Python-Flask",

        "Transformers",

        "Diffusion Models",

        "OpenAI Realtime API",
      ],

      skills: [
        "AI",

        "ML",

        "TTS",

        "NLP",

        "React",

        "Node.js",

        "Pytorch",

        "OpenAI",

        "Diffusion Models",

        "Transformers",

        "Speech-to-Text",

        "Text-to-Speech",
      ],
    },
  ];

  const handleShowModal = (project) => {
    setSelectedProject(project);

    setModalShow(true);
  };

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col>
            <h3 className="section-header text-left">Projects</h3>

            <p style={{ fontSize: "1.2rem", left: "0" }}>
              Here are some of my recent projects:
            </p>
          </Col>
        </Row>

        <Row>
          {portfolioData.map((project, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <StyledCard
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                style={{
                  backgroundImage: `url(${project.image})`,
                }}
                onClick={() => handleShowModal(project)}
              >
                <StyledCardContent>
                  <CardBackdrop>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      <Typography
                        color="white"
                        fontWeight="bold"
                        fontSize="1.2rem"
                        mb={1}
                        sx={{ fontFamily: "'Arial', sans-serif" }}
                      >
                        {project.title}
                      </Typography>
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 }}
                    >
                      <Typography
                        color="white"
                        fontWeight="regular"
                        fontSize="0.8rem"
                        lineHeight="1.2"
                        sx={{ fontFamily: "'Arial', sans-serif" }}
                      >
                        {project.description}
                      </Typography>
                    </motion.div>
                  </CardBackdrop>

                  <Box px={2} pb={2}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.6 }}
                    >
                      <Button
                        variant="primary"
                        onClick={() => handleShowModal(project)}
                        className="mt-auto btn-custom-secondary"
                      >
                        Learn More
                      </Button>
                    </motion.div>
                  </Box>
                </StyledCardContent>
              </StyledCard>
            </Col>
          ))}
        </Row>
      </Container>

      <PortfolioModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={selectedProject ? selectedProject.title : ""}
        content={selectedProject}
      />
    </section>
  );
};

export default Projects;
