// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skills-scroll-window {
  padding-right: 15px;
  background-color: #1e1e1e;
  color: #e0e0e0;
  padding: 20px;
  border-radius: 10px;
}

.skills-scroll-window::-webkit-scrollbar {
  width: 8px;
}

.skills-scroll-window::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.skills-scroll-window::-webkit-scrollbar-thumb {
  background: #888;
}

.skills-scroll-window::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.skill-label {
  cursor: pointer;
  text-decoration: underline;
  color: #ff6384;
}

.skill-label:hover {
  color: #ff4567;
}

.shadow-box {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-shadow {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
}

.text-box-shadow {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/styles/Skills.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,uEAAuE;AACzE;;AAEA;EACE,wEAAwE;AAC1E;;AAEA;EACE,yEAAyE;AAC3E","sourcesContent":[".skills-scroll-window {\n  padding-right: 15px;\n  background-color: #1e1e1e;\n  color: #e0e0e0;\n  padding: 20px;\n  border-radius: 10px;\n}\n\n.skills-scroll-window::-webkit-scrollbar {\n  width: 8px;\n}\n\n.skills-scroll-window::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n\n.skills-scroll-window::-webkit-scrollbar-thumb {\n  background: #888;\n}\n\n.skills-scroll-window::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n\n.skill-label {\n  cursor: pointer;\n  text-decoration: underline;\n  color: #ff6384;\n}\n\n.skill-label:hover {\n  color: #ff4567;\n}\n\n.shadow-box {\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);\n}\n\n.btn-shadow {\n  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);\n}\n\n.text-box-shadow {\n  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
