// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PortfolioItemModal.css */

.portfolio-modal .modal-content {
  width: 100%;
  max-height: 70vh;
  margin: 0 auto;
}

.portfolio-modal .modal-header {
  padding: 0.5rem 1rem;
}

.portfolio-modal .modal-body {
  padding: 1rem;
  max-height: 60vh;
  overflow-y: auto;
}

.portfolio-modal .modal-footer {
  padding: 0.5rem 1rem;
}

.portfolio-modal .project-details {
  font-size: 0.9rem;
  color: #666;
}

.portfolio-modal .project-body {
  font-size: 0.95rem;
}

.portfolio-modal .feature-list {
  padding-left: 1.2rem;
  font-size: 0.9rem;
}

.portfolio-modal .feature-list li {
  margin-bottom: 0.5rem;
}

.portfolio-modal img {
  max-height: 200px;
  object-fit: cover;
  width: 100%;
}

.portfolio-modal p {
  color: #cccccc;
}

.portfolio-modal ul {
  color: #cccccc;
}

.portfolio-modal .btn-primary {
  background-color: #36a2eb;
  border: none;
  transition: background-color 0.3s ease;
}

.portfolio-modal .btn-primary:hover {
  background-color: #1e90ff;
}
`, "",{"version":3,"sources":["webpack://./src/styles/PortfolioItemModal.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* PortfolioItemModal.css */\n\n.portfolio-modal .modal-content {\n  width: 100%;\n  max-height: 70vh;\n  margin: 0 auto;\n}\n\n.portfolio-modal .modal-header {\n  padding: 0.5rem 1rem;\n}\n\n.portfolio-modal .modal-body {\n  padding: 1rem;\n  max-height: 60vh;\n  overflow-y: auto;\n}\n\n.portfolio-modal .modal-footer {\n  padding: 0.5rem 1rem;\n}\n\n.portfolio-modal .project-details {\n  font-size: 0.9rem;\n  color: #666;\n}\n\n.portfolio-modal .project-body {\n  font-size: 0.95rem;\n}\n\n.portfolio-modal .feature-list {\n  padding-left: 1.2rem;\n  font-size: 0.9rem;\n}\n\n.portfolio-modal .feature-list li {\n  margin-bottom: 0.5rem;\n}\n\n.portfolio-modal img {\n  max-height: 200px;\n  object-fit: cover;\n  width: 100%;\n}\n\n.portfolio-modal p {\n  color: #cccccc;\n}\n\n.portfolio-modal ul {\n  color: #cccccc;\n}\n\n.portfolio-modal .btn-primary {\n  background-color: #36a2eb;\n  border: none;\n  transition: background-color 0.3s ease;\n}\n\n.portfolio-modal .btn-primary:hover {\n  background-color: #1e90ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
