// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  margin-top: 40px;
}

.icon-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
  transition: all 0.3s ease;
}

.icon-link:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.linkedin-bg {
  background-color: #0077b5;
}

.github-bg {
  background-color: #333;
}

.email-bg {
  background-color: #ea4335;
}

.icon-up-bg {
  background-color: #6c757d;
  border: none;
  cursor: pointer;
}

.copyright {
  font-size: 14px;
  color: #6c757d;
}

@media (max-width: 576px) {
  .site-footer .text-right {
    text-align: center !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;EAC3B,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".site-footer {\n  background-color: #f8f9fa;\n  padding: 20px 0;\n  margin-top: 40px;\n}\n\n.icon-link {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  color: #fff;\n  font-size: 20px;\n  margin-right: 10px;\n  transition: all 0.3s ease;\n}\n\n.icon-link:hover {\n  transform: translateY(-3px);\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.linkedin-bg {\n  background-color: #0077b5;\n}\n\n.github-bg {\n  background-color: #333;\n}\n\n.email-bg {\n  background-color: #ea4335;\n}\n\n.icon-up-bg {\n  background-color: #6c757d;\n  border: none;\n  cursor: pointer;\n}\n\n.copyright {\n  font-size: 14px;\n  color: #6c757d;\n}\n\n@media (max-width: 576px) {\n  .site-footer .text-right {\n    text-align: center !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
