// PortfolioModal.js

import React from "react";

import { Modal, Button } from "react-bootstrap";

import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";

import "../styles/PortfolioItemModal.css";

const PortfolioModal = ({ show, onHide, title, content }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="portfolio-item-modal"
      centered
      className="portfolio-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="portfolio-item-modal">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {content && content.image && (
          <img src={content.image} alt={title} className="img-fluid mb-3" />
        )}

        {content && content.details && (
          <p className="project-details mb-2">{content.details}</p>
        )}

        {content && content.body && (
          <p className="project-body mb-2">{content.body}</p>
        )}

        {content && content.features && (
          <ul className="feature-list">
            {content.features.map((feature, idx) => (
              <li key={idx}>
                <strong>{feature.title}:</strong> {feature.description}
              </li>
            ))}
          </ul>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <div>
            {content && content.githubUrl && (
              <Button
                variant="link"
                href={content.githubUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="me-2 p-0"
              >
                <FaGithub size={20} />
              </Button>
            )}

            {content && content.demoUrl && (
              <Button
                variant="link"
                href={content.demoUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="p-0"
              >
                <FaExternalLinkAlt size={20} />
              </Button>
            )}
          </div>

          <Button variant="secondary" size="sm" onClick={onHide}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PortfolioModal;
