import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../styles/WhyHireMeCLI.css";

const skills = [
  {
    id: 1,
    name: "Practical Knowledge",
    description:
      "Hands-on experience in AI through real-world projects, giving practical insights and problem-solving skills directly applicable to AI development.",
  },
  {
    id: 2,
    name: "Adaptability",
    description:
      "Thrives in rapidly changing environments, quickly learning and integrating new technologies and methodologies to stay ahead in the AI field.",
  },
  {
    id: 3,
    name: "Resourcefulness",
    description:
      "Journey has honed ability to find and utilize a vast array of resources, from online courses to AI communities, empowering independent problem-solving and continuous improvement.",
  },
  {
    id: 4,
    name: "Passion-Driven",
    description:
      "Enthusiasm for AI drives exploration of niche areas and innovative solutions, pushing the boundaries of what's possible in this exciting field.",
  },
  {
    id: 5,
    name: "Continual Learning",
    description:
      "Committed to staying on the cutting edge of AI technology, continuously updating skills and applying the latest advancements to projects.",
  },
  {
    id: 6,
    name: "Diverse Perspective",
    description:
      "Brings fresh insights and innovative approaches to problem-solving in AI, fostering creativity and pushing the boundaries of innovation.",
  },
  {
    id: 7,
    name: "Proven Problem-Solving Skills",
    description:
      "Self-directed learning has equipped with advanced problem-solving skills and resilience, critical traits for thriving in the dynamic field of AI.",
  },
  {
    id: 8,
    name: "Demonstrated Commitment",
    description:
      "Dedication to AI, showcased through self-driven learning journey, reflects a deep passion for the work and relentless pursuit of excellence.",
  },
];

const WhyHireMeCLI = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState([]);
  const [stage, setStage] = useState(0);
  const [showCursor, setShowCursor] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      inputRef.current.focus();
    }
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      const cursorInterval = setInterval(() => {
        setShowCursor((prev) => !prev);
      }, 500);
      return () => clearInterval(cursorInterval);
    }
  }, [isActive]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [output]);

  const addLine = (text, delay = 0, format = "") => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setOutput((prev) => [...prev, { text, format }]);
        resolve();
      }, delay);
    });
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedInput = input.trim();
    if (trimmedInput === "") return;
    addLine(`C:\\> ${trimmedInput}`, 0, "");
    processCommand(trimmedInput.toLowerCase());
    setInput("");
  };

  const processCommand = (command) => {
    switch (command) {
      case "y":
      case "a":
        if (stage === 1) setStage(2);
        break;
      case "pip install wes lagarde":
        setStage(3);
        break;
      case "help":
        showHelp();
        break;
      case "clear":
        setOutput([]);
        break;
      case "skills":
        showSkills();
        break;
      default:
        addLine(`Command not recognized: ${command}`, 0, "error");
    }
  };

  const showHelp = () => {
    addLine("Available commands:", 0, "bold");
    addLine("help     - Show this help message", 0);
    addLine("clear    - Clear the screen", 0);
    addLine("skills   - List all of Wes Lagarde's skills", 0);
    addLine("pip install wes lagarde - Install Wes Lagarde's skillset", 0);
  };

  const showSkills = async () => {
    await addLine("Wes Lagarde's Skills:", 0, "bold");
    for (const skill of skills) {
      await addLine(`${skill.name}`, 3000, "skill-title");
      await addLine(`${skill.description}`, 4000, "skill-description");
    }
  };

  useEffect(() => {
    const runStage = async () => {
      if (isActive && stage === 0) {
        await addLine(
          "C:\\> Would you like to know more about Wes Lagarde?",
          1000
        );
        await addLine(
          "C:\\> Please type (y)es for yes or (a)bsolutely for yes.",
          1000
        );
        setStage(1);
      } else if (stage === 2) {
        await addLine("C:\\> Help Commands for Wes Lagarde:", 1000, "bold");
        await addLine(
          'C:\\> "pip install Wes Lagarde"  -  Load Wes Lagarde\'s Skillset',
          1000
        );
        await addLine("C:\\> Type 'help' for more commands", 1000);
      } else if (stage === 3) {
        await addLine(
          "C:\\> Loading Wes Lagarde.... Please wait...",
          1000,
          "loading"
        );
        for (const [index, skill] of skills.entries()) {
          await addLine(
            `C:\\> Loaded Asset ${index + 1}:`,
            3000,
            "asset-loaded"
          );
          await addLine(
            `${"_".repeat(10)} ${skill.name} ${"_".repeat(10)}`,
            3000,
            "skill-title"
          );

          await addLine(skill.description, 2000, "skill-description");

          await addLine("", 1000); // Empty line for spacing
        }
        await addLine(
          "C:\\> Installation complete. Wes Lagarde is ready to innovate!",
          3000,
          "success"
        );
      }
    };
    runStage();
  }, [stage, isActive]);

  const getLineStyle = (format) => {
    switch (format) {
      case "bold":
        return "font-bold";
      case "skill-title":
        return "font-bold text-xl mt-2";
      case "skill-description":
        return "text-sm";
      case "asset-loaded":
        return "text-yellow-400";
      case "loading":
        return "animate-pulse";
      case "success":
        return "font-bold text-green-300";
      case "error":
        return "text-red-500";
      default:
        return "";
    }
  };

  const handleActivation = () => {
    if (!isActive) {
      setIsActive(true);
      addLine("C:\\> Type 'help' for available commands.", 0);
    }
  };

  return (
    <div className="why-hire-me-cli">
      <h3 className="cli-subheader">
        For more information about Wes, click the window below:
      </h3>
      <div
        className="cli-container"
        style={{
          border: "2px solid #FFFFFF",
          background: "black",
          color: "#00ff00",
          width: "65vw",
          height: "400px", // Set a fixed height for the container
          fontSize: "0.8em",
          fontWeight: "regular",
          fontFamily: "'Courier New', Courier, monospace",
          borderRadius: "10px",
          boxShadow: "0 10px 20px rgba(0,0,0,0.39), 0 6px 6px rgba(0,0,0,0.23)",
          overflow: "hidden",
          cursor: isActive ? "text" : "pointer",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={handleActivation}
      >
        <div ref={contentRef} className="cli-content flex-grow overflow-y-auto">
          <AnimatePresence>
            {output.map((line, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className={`${getLineStyle(line.format)} mb-1`}
              >
                {line.text}
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
        {isActive && (
          <div className="cli-input-area p-2 border-t border-gray-700">
            <form onSubmit={handleSubmit} className="flex items-center">
              <span>C:\&gt;&nbsp;</span>
              <div className="relative flex-grow">
                <input
                  ref={inputRef}
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                  className="cli-input w-full bg-transparent outline-none"
                  autoFocus
                />
                <span
                  className={`cli-cursor absolute top-0 left-0 ${
                    showCursor ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-100`}
                  style={{ left: `${input.length * 0.6}em` }}
                >
                  ▮
                </span>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default WhyHireMeCLI;
