import React, { useState, useEffect } from "react";

import { Navbar, Nav, Container } from "react-bootstrap";

import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";

import logo from "../assets/img/wes_lagarde_website_logo.png";

import "../styles/Header.css";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;

      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <Navbar
      expand="lg"
      fixed="top"
      className={`site-header ${scrolled ? "navbar-scrolled" : ""}`}
    >
      <Container>
        <Navbar.Brand href="#home" className="site-logo">
          <img src={logo} alt="Wes Lagarde" className="logo-img" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#about">About</Nav.Link>

            <Nav.Link href="#skills">Skills</Nav.Link>

            <Nav.Link href="#projects">Projects</Nav.Link>

            <Nav.Link href="#contact">Contact</Nav.Link>
          </Nav>

          <Nav className="ms-auto social-icons">
            <Nav.Link
              href="https://www.linkedin.com/in/wes-lagarde-43ab38143"
              target="_blank"
            >
              <FaLinkedin />
            </Nav.Link>

            <Nav.Link href="https://github.com/wesley7137" target="_blank">
              <FaGithub />
            </Nav.Link>

            <Nav.Link href="mailto:weslagarde@gmail.com">
              <FaEnvelope />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
