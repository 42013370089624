import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";
import "../styles/Contact.css";
import "../styles/custom.css";
import "../styles/Projects.css";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <section className="contact" id="connect">
      <Container>
        <Row>
          <Col md={8}>
            <h2 className="section-header">Get In Touch</h2>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Your message"
                  required
                />
              </Form.Group>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
              >
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3 btn-custom-secondary"
                >
                  Send
                </Button>
              </motion.div>
            </Form>
          </Col>
          <Col md={4} className="social-links-column">
            <div className="social-links-wrapper">
              <a
                className="icon-link linkedin-bg"
                href="https://www.linkedin.com/in/wes-lagarde-43ab38143"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <FaLinkedin />
              </a>
              <a
                className="icon-link github-bg"
                href="https://github.com/wesley7137"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="GitHub"
              >
                <FaGithub />
              </a>
              <a
                className="icon-link email-bg"
                href="mailto:weslagarde@gmail.com"
                aria-label="Email"
              >
                <FaEnvelope />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
