import React from "react";
import { Container } from "react-bootstrap";
import wesImageDesktop from "../assets/img/wes-lagarde-low-res.png";
import wesImageMobile from "../assets/img/wes-lagarde-mobile.png"; // Add this new import
import "../styles/Hero.css";
import "../styles/custom.css";

const Hero = () => {
  return (
    <section id="hero" className="hero">
      <div
        className="hero-background"
        style={{ backgroundImage: `url(${wesImageDesktop})` }}
      ></div>
      <div
        className="hero-background-mobile"
        style={{ backgroundImage: `url(${wesImageMobile})` }}
      ></div>
      <div className="hero-overlay"></div>
      <Container fluid className="hero-container">
        <div className="hero-quote-box shadow-box">
          <blockquote className="hero-quote">
            "Artificial intelligence is not a substitute for human intelligence;
            it is a tool to amplify human creativity and ingenuity."
          </blockquote>
          <p className="hero-quote-author">- Fei-Fei Li</p>
        </div>
        <div className="hero-buttons">
          <a href="#about" className="btn-custom btn-shadow">
            About Me
          </a>
          <a href="#projects" className="btn-custom btn-shadow">
            Explore My Work
          </a>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
