import React, { useRef, useEffect } from "react";
import "../styles/Skills.css";

const SkillsScrollWindow = ({ skills, activeSkill }) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (activeSkill && scrollRef.current) {
      const skillElement = scrollRef.current.querySelector(
        `#skill-${activeSkill.replace(/\s+/g, "-").toLowerCase()}`
      );
      if (skillElement) {
        skillElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [activeSkill]);

  return (
    <div
      className="skills-scroll-window"
      ref={scrollRef}
      style={{
        maxHeight: "500px",
        overflowY: "auto",
        backgroundColor: "#1e1e1e",
        color: "#e0e0e0",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      {skills.map((skill, index) => (
        <div
          key={index}
          className="skill-item mb-3"
          id={`skill-${skill.name.replace(/\s+/g, "-").toLowerCase()}`}
          style={{
            borderBottom: "1px solid #444",
            paddingBottom: "15px",
            marginBottom: "15px",
          }}
        >
          <h4
            style={{
              color: "#ff6384",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            {skill.name}
          </h4>
          <p
            style={{
              color: "white",

              fontSize: "0.9em",
              marginBottom: "10px",
            }}
          >
            {skill.details}
          </p>
          <h5
            style={{ color: "#4bc0c0", marginBottom: "5px", fontSize: "1em" }}
          >
            Examples:
          </h5>
          <p style={{ fontSize: "0.9em", marginBottom: "10px" }}>
            {skill.examples}
          </p>
          <h5
            style={{ color: "#4bc0c0", marginBottom: "5px", fontSize: "1em" }}
          >
            Related Projects:
          </h5>
          <ul style={{ paddingLeft: "20px", marginBottom: "0" }}>
            {skill.projects.map((project, pIndex) => (
              <li key={pIndex} style={{ marginBottom: "5px" }}>
                <a
                  href={project.link}
                  style={{ color: "#36a2eb", textDecoration: "none" }}
                >
                  {project.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default SkillsScrollWindow;
