// About.js
import React from "react";
import { Container } from "react-bootstrap";
import cv from "../assets/files/Wes_Lagarde_Resume.pdf";
import "../styles/About.css";
import "../styles/custom.css";

const About = () => {
  return (
    <section id="about" className="about-section">
      <Container className="about-container">
        <div className="shadow-box">
          <h3 className="section-header">ABOUT ME</h3>
          <div className="about-content">
            <p className="about-paragraph">
              Welcome! My name is{" "}
              <span className="about-highlight">Wes Lagarde</span>. As an AI and
              Machine Learning specialist, I'm dedicated to turning this vision
              into reality. Let's collaborate and create AI solutions that will
              shape the future of technology and business.
            </p>
            <p className="about-paragraph">
              Innovative{" "}
              <span className="about-highlight">
                AI and Machine Learning Specialist
              </span>{" "}
              with extensive expertise in large language models (LLMs), prompt
              engineering, and advanced neural network architectures. Proven
              track record in developing and optimizing AI solutions across
              diverse domains, including NLP, computer vision, and molecular
              biology. Proficient in Python, JavaScript, and cutting-edge ML
              frameworks such as TensorFlow, PyTorch, and Hugging Face. Combines
              technical prowess with strong business acumen, leveraging insights
              from sales and consumer behavior to create impactful AI
              applications.
            </p>
            <a
              href={cv}
              className="cv-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download my CV
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default About;
